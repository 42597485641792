import { jobClassification as getJobClassification } from '@tymbe/job-classifications/job-clasification';
import { getConst } from '@tymbe/legislatives/Constants';
import { CzechConstantType } from '@tymbe/legislatives/czechia/CzechConstantType';
import { CompanyData } from '@tymbe/schema/company.interface';
import { Form, FormState } from 'informed';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { DeepPartial } from 'utility-types';

import feathersClient from '../../../../apiClient';
import { useUser } from '../../../../apiClient/ApiContext';
import { ErrorAlert, SuccessAlert } from '../../../../components/alerts';
import { resolveUploadableFiles } from '../../../../components/blob/blob.utils';
import { SubmitButton } from '../../../../components/buttons';
import { getValuesForPatch } from '../../../../components/forms/form-util';
import Card from '../../../../components/Layout/Card';
import Spinner from '../../../../components/Spinner';
import Wrapper from '../../../../components/wrapper';
import Container from '../../../../containers';
import { RequestBody } from '../../../../types/ReactQuery';
import { Roles } from '../../../../utils/enums';
import { mapCompanyData } from '../../company.utils';
import CompanyForm,
{ CompanyFormValues, companyTypeOptions, countryOptions, stateOptions }
  from '../../components/CompanyForm';
import { transformShiftLabels } from '../shift-template/utils/ShiftTemplate.utils';

const EditCompany = () => {
  const [formDisabled, setFormDisabled] = useState<boolean>(false);
  const canChangeDefaultSettings = useUser().hasRoles([
    Roles.SUPER_ADMIN,
    Roles.TYMBE_ADMIN,
    Roles.TYMBE_COORDINATOR,
  ]);
  const { companyId } = useParams();

  const history = useNavigate();

  const { mutateAsync: patchCompany } = useMutation(
    ['PatchCompany'],
    (req: RequestBody<DeepPartial<CompanyData> | FormData>): Promise<CompanyData> =>
      feathersClient.service('company').patch(req.id, req.body as Partial<CompanyData>, req.params),
  );

  const { data: company, isLoading } = useQuery(
    ['FetchCompanyDetail', companyId],
    async (): Promise<CompanyData> =>
      feathersClient.service('company').get(companyId!, {
        query: {
          $eager: '[address, contactPerson, contractor, defaultSettings.[position, defaultPerk, defaultUtility, defaultDocumentType]]',
        },
      }),

    { enabled: !!companyId },
  );

  if (!companyId) {
    return <Navigate to="/company" />;
  }

  const onSubmit = async (formState: FormState<CompanyFormValues>) => {
    if (!companyId) {
      if (!company?.address_id) ErrorAlert('Nebylo možné dohledat identifikátor adresy');
      history('/company');
      return;
    }
    setFormDisabled(true);
    const modifiedAndNulled = getValuesForPatch(formState);
    const { values, modified } = formState;
    const companyData = mapCompanyData({
      ...modifiedAndNulled as CompanyFormValues,
      address_id: company?.address_id || undefined,
      company_id: companyId,
      settings_id: company?.defaultSettings?.id,
    });

    if (modified.pay_supplement && values.pay_supplement) {
      companyData.defaultSettings = {
        ...companyData.defaultSettings,
        pay_supplement: values.pay_supplement,
      };
    }

    companyData.defaultSettings = {
      ...companyData.defaultSettings,
      max_payment_base: Number(values.max_payment_base),
      min_payment_base: Number(values.min_payment_base),
      max_credits: Number(values.max_credits),
      min_credits: Number(values.min_credits),
    };

    if (!canChangeDefaultSettings) {
      delete companyData.defaultSettings;
    }

    try {
      await patchCompany({ id: companyId, body: companyData });
      const formData = resolveUploadableFiles(modifiedAndNulled);
      if (formData) {
        await patchCompany(
          { id: companyId, body: formData, params: { headers: { 'Content-Type': 'multipart/form-data' } } },
        );
      }
      SuccessAlert('Firma byla úspěšně upravena');
      history('/company');
    } catch (err) {
      if ((err as Error).message === 'Can\'t change fulfillment to agency when some shift-template contains forbidden ISCO') {
        ErrorAlert(
          'Nelze změnit režim spolupráce na Agenturu, když existuje pracovní pozice s nepovolenou klasifikací ISCO',
        );
      } else if ((err as Error).message === 'Can\'t change fulfillment to agency when some future shift contains forbidden ISCO') {
        ErrorAlert(
          'Nelze změnit režim spolupráce na Agenturu, když existuje směna v budoucnosti s nepovolenou klasifikací ISCO',
        );
      } else {
        ErrorAlert('Při editaci adresy firmy došlo k chybě');
      }
    }
    setFormDisabled(false);
  };

  const mapInitialData = () => {
    if (!company) return undefined;
    const iscoId = company.defaultSettings?.job_classification_ids?.cz_isco;
    const naceId = company.defaultSettings?.job_classification_ids?.cz_nace;
    const minimumWage = getConst(
      CzechConstantType.MINIMUM_WAGE,
      new Date().toISOString(),
      'europe/prague',
    );
    return {
      ...company,
      job_classification_isco: iscoId ? {
        id: iscoId,
        name: getJobClassification({ id: iscoId, type: 'cz_isco' }),
      } : undefined,
      job_classification_nace: naceId ? {
        id: naceId,
        name: getJobClassification({ id: naceId, type: 'cz_nace' }),
      } : undefined,
      labels: company.defaultSettings?.labels && transformShiftLabels(company.defaultSettings?.labels),

      stateOption: stateOptions.find((x) => x.value === company.state),
      countryOption: countryOptions.find((x) => x.value === company.address?.country) || countryOptions[0],
      typeOption: companyTypeOptions.find((x) => x.value === company.fulfillment_type),
      pay_supplement: company.defaultSettings?.pay_supplement,

      defaultRequirements: [...company.defaultSettings?.defaultPerk || [],
        ...company.defaultSettings?.defaultDocumentType || [],
      ],

      defaultPerk: company.defaultSettings?.defaultPerk,
      defaultUtility: company.defaultSettings?.defaultUtility,
      position: company.defaultSettings?.position,
      max_payment_base: company.defaultSettings?.max_payment_base || (Math.round((minimumWage / 168) * 10) / 10) * 2,
      min_payment_base: company.defaultSettings?.min_payment_base || Math.round((minimumWage / 168) * 10) / 10,
      max_credits: company.defaultSettings?.max_credits || 50,
      min_credits: company.defaultSettings?.min_credits || 0,
    };
  };

  return (
    <Container
      iconcolor="#B3CA1F"
      background="#fff"
      elevate
      contentstyle={{ paddingLeft: '170px' }}
      desktopHeader
      sidebar
    >
      <Wrapper padding="0px" margin="0px 22px 18px 31px">
        <h1> Editace firmy </h1>
        <Card>
          {isLoading ? <Spinner />
            : (
              <Form<CompanyFormValues> name="company-form" onSubmit={onSubmit} initialValues={mapInitialData()}>
                <CompanyForm company={company} />
                <SubmitButton disabled={formDisabled} style={{ marginTop: '18px' }} buttontext="Uložit" />
              </Form>
            )}
        </Card>
      </Wrapper>
    </Container>
  );
};

export default EditCompany;
