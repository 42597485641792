import { BlockReasons } from '@tymbe/schema/enums';
import { PaySupplementAmountType } from '@tymbe/schema/pay-supplement.interface';

export const enum Gender {
  MALE = 'male',
  FEMALE = 'female',
}

export const GenderDisplay = {
  [Gender.MALE]: 'Muž',
  [Gender.FEMALE]: 'Žena',
};

export const ApplicationState = {
  CONFIRMED: 'confirmed',
  REJECTED: 'rejected',
  SYSTEM_CANCELED: 'system_canceled',
  CANCELED_EARLY: 'canceled_early',
  CANCELED_LATE: 'canceled_late',
};
export const ApplicationStateDisplay = {
  [ApplicationState.CONFIRMED]: 'Obsazeno',
  [ApplicationState.REJECTED]: 'Pozvánka odmítnuta',
  [ApplicationState.SYSTEM_CANCELED]: 'Směna zrušena systémem',
  [ApplicationState.CANCELED_EARLY]: 'Směna zrušena v termínu',
  [ApplicationState.CANCELED_LATE]: 'Směna zrušena po termínu',
};

export const BlockReasonDisplay = {
  [BlockReasons.PREVENTION]: 'Prevence',
  [BlockReasons.LOW_PERFORMANCE]: 'Nízký výkon',
  [BlockReasons.INAPPROPRIATE_BEHAVIOUR]: 'Nevhodné chování',
  [BlockReasons.VIOLATION_OF_DISCIPLINE]: 'Hrubé porušení kázně',
  [BlockReasons.THEFT]: 'Krádež',
  [BlockReasons.OTHER]: 'Jiné',
  [BlockReasons.HISTORICAL_BLOCK]: 'Historická blokace',
};

export const enum AttendanceResolution {
  UNEXCUSED_ABSENCE = 'unexcused_absence',
  ADMIN_EXCUSED_ABSENCE = 'admin_excused_absence',
  EXCUSED_ABSENCE = 'excused_absence',
  CANCEL = 'cancel',
  OK = 'ok',
  BREACH_OF_DISCIPLINE = 'breach_of_discipline',
}

export const AttendanceResolutionDisplay = {
  [AttendanceResolution.UNEXCUSED_ABSENCE]: 'Neomluvená absence',
  [AttendanceResolution.ADMIN_EXCUSED_ABSENCE]: 'Absence omluvená administrátorem',
  [AttendanceResolution.EXCUSED_ABSENCE]: 'Omluvená absence',
  [AttendanceResolution.CANCEL]: 'Zrušená směna',
  [AttendanceResolution.OK]: 'Docházka potvrzena',
  [AttendanceResolution.BREACH_OF_DISCIPLINE]: '',
};

export const DocumentType = {
  ID_CARD: 'id_card',
  HEALTH_CARD: 'health_card',
  CRIMINAL_RECORD: 'criminal_record',
  DRIVING_LICENCE: 'driving_license',
  CONTRACT_DPP: 'contract_dpp',
  CONTRACT_DPP_TEMPLATE: 'contract_dpp_template',
  OTHER: 'other',
  MEDICAL_EXAMINATION: 'medical_examination',
};

export const CompanyState = {
  PENDING: 'pending',
  ACTIVE: 'active',
  BANNED: 'banned',
};

export const BranchofficeType = {
  AREA: 'area',
  BRANCH: 'branch',
  DEPARTMENT: 'department',
};

export const SurveyQuestionType = {
  TEXT: 'text',
  LONG_TEXT: 'longtext',
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  DROPDOWN: 'dropdown',
};

export const Perk = {
  MAN_ONLY: 1,
  WOMAN_ONLY: 2,
  ADULT: 3,
  COMPANY_SENIOR: 4,
  BRANCHOFFICE_SENIOR: 5,
  POSITION_SENIOR: 6,
};

export enum ContactType {
  EMAIL = 'email',
  MOBILE_PHONE = 'mobile_phone',
  LANDLINE = 'landline',
  OTHER = 'other',
}

export const MyJobType = {
  INVITATION: 'invitation',
  AWOL: 'awol',
  JOB: 'myjob',
  HISTORY: 'history',
};
export const DurationUnit = {
  MINUTE: 'minute',
  HOUR: 'hour',
};

export const enum Roles {
  SUPER_ADMIN = 'super_admin',
  TYMBE_ADMIN = 'tymbe_admin',
  ADMIN = 'admin',
  TYMBE_COORDINATOR = 'tymbe_coordinator',
  COMPANY = 'company_admin',
  BRANCHOFFICE_MANAGER = 'branchoffice_manager',
  BRANCHOFFICE_SUPERVISOR = 'branchoffice_supervisor',
  SHIFT_SUPERVISOR = 'shift_supervisor',
  TYMBER = 'tymber',
}

export const RoleDisplay = {
  [Roles.SUPER_ADMIN]: 'Super Admin',
  [Roles.TYMBE_ADMIN]: 'Tymbe Admin',
  [Roles.ADMIN]: 'Administrátor',
  [Roles.TYMBE_COORDINATOR]: 'Tymbe Koordinátor',
  [Roles.COMPANY]: 'Firemní Administrátor',
  [Roles.BRANCHOFFICE_MANAGER]: 'Manažer Provozovny',
  [Roles.BRANCHOFFICE_SUPERVISOR]: 'Vedoucí Provozovny',
  [Roles.SHIFT_SUPERVISOR]: 'Vedoucí Směny',
  [Roles.TYMBER]: 'Tymber',
};

export const PerkGroup = {
  SEX: 'sex',
  AGE: 'age',
  QUALIFICATION: 'QUALIFICATION',
  NATIONALITY: 'nationality',
};

export const PerkId = {
  ANY_GENDER: 'any_gender',
  MAN_ONLY: 'man_only',
  WOMAN_ONLY: 'woman_only',
  ADULT: 'adult',
  ANY_AGE: 'any_age',
  ANY_QUALIFICATION: 'any_qualification',
  COMPANY_SENIOR: 'company_senior',
  COMPANY_JUNIOR: 'company_junior',
  BRANCHOFFICE_SENIOR: 'branchoffice_senior',
  POSITION_SENIOR: 'position_senior',
  EU_CITIZEN: 'eu_citizen',
  INFORMATION_CARD: 'information_card',
  /** @deprecated */
  NON_EU_CITIZEN: 'non_eu_citizen',
  NON_UA_PROTECTED: 'non_ua_protected',
  CACHIER: 'cachier',
  BILLA_SERVICE_DESK: 'billa_service_desk',
  BILLA_BAKERY: 'billa_bakery',
};

export const enum ActionTypes {
  VERIFY_SIGNUP = 'verifySignup',
  SEND_VERIFY_SIGNUP = 'sendVerifySignup',
  RESET_PASSWORD = 'resetPassword',
  SEND_RESET_PASSWORD = 'sendResetPassword',
  SEND_BANNED_SHIFTS_INFO = 'sendBannedShiftsInfo',
  VERIFY_PASSWORD = 'verifyPassword',
}

export const enum AccountState {
  ACTIVE = 'active',
  UNVERIFIED_PROOF_OF_IDENTITY = 'unverified_proof_of_identity',
  UNVERIFIED_ACCOUNT = 'unverified_account',
  BAN = 'ban',
  COMPANY = 'company',
  ADMIN = 'admin',
}

export const enum EditDocumentAction {
  EDITOR_JS = 'editorJs',
  GOOGLE = 'google',
  UNKNOWN = 'unknown',
}

export const PaySupplementAmountTypeDisplay = {
  [PaySupplementAmountType.Fixed]: 'Fixní částka',
  [PaySupplementAmountType.Percentage]: 'V procentech',
};
