import { DocumentTypeData } from '@tymbe/schema/document-type.interface';
import { CompanyFulfillmentType, DocumentType } from '@tymbe/schema/enums';
import { PersonData } from '@tymbe/schema/person.interface';
import moment from 'moment/moment';
import { DeepPartial } from 'utility-types';

import { CompanyFormValues } from './components/CompanyForm';
import feathersClient from '../../apiClient';
import { ErrorAlert } from '../../components/alerts';
import {
  AddressData,
  CompanyData,
  isDocumentTypeData,
  isPerkData,
} from '../../types/TymbeApi';

export const mapCompanyData = (
  values: Omit<
  CompanyFormValues,
  'address_id' | 'company_id' | 'settings_id'
  > & { address_id?: number; company_id: string; settings_id?: number },
): DeepPartial<CompanyData> => {
  const companyData: DeepPartial<CompanyData> = {
    state: values.stateOption?.value,
    name: values.name,
    cin: values.cin,
    display_name: values.display_name,
    tin: values.tin,
    file_number: values.file_number,
    bank_account: values.bank_account,
    payment_bank_account: values.payment_bank_account,
    note: values.note,
    fulfillment_type: values.typeOption?.value,
    cssz_vs: values.cssz_vs,
    address: {
      id: values?.address_id,
      country: values?.countryOption?.value,
      addressline1: values?.address?.addressline1,
      locality: values?.address?.locality,
      zip: values?.address?.zip,
    } as AddressData,
    power_of_attorney_from:
      values.power_of_attorney_from
      && moment(values.power_of_attorney_from)
        .tz('Europe/Prague')
        .startOf('day')
        .toISOString(),
    power_of_attorney_to:
      values.power_of_attorney_to
      && moment(values.power_of_attorney_to)
        .tz('Europe/Prague')
        .endOf('day')
        .toISOString(),
  };

  // Map contact person
  if (values.contactPerson) {
    if (values.contactPerson.id === 0) {
      companyData.contactPerson = {
        first_name: values.contactPerson.first_name,
        middle_name: values.contactPerson.middle_name,
        last_name: values.contactPerson.last_name,
      } as PersonData;
    } else {
      companyData.contact_person = values.contactPerson.id;
    }
  }

  // Map contractors
  if (
    values.typeOption?.value !== CompanyFulfillmentType.MANDATE
    && values.contractor
  ) {
    let contractors: CompanyData[] = [];
    if (!Array.isArray(values.contractor)) {
      contractors.push(values.contractor);
    } else {
      contractors = values.contractor;
    }
    companyData.contractor = contractors.map(
      (x) => ({ id: x.id } as CompanyData),
    );
  } else {
    companyData.contractor = [];
  }

  companyData.defaultSettings = {
    id: values.settings_id,
    company_id: Number(values.company_id),
    position_id: values.position?.id,
    labels: values.labels?.map((label) => label.value),
    max_payment_base: values.max_payment_base,
    min_payment_base: values.min_payment_base,
    max_credits: values.max_credits,
    min_credits: values.min_credits,
    defaultUtility: values.defaultUtility === null ? [] : values.defaultUtility,
    pay_supplement: values.pay_supplement,
  };

  if (values.defaultRequirements?.length) {
    companyData.defaultSettings.defaultDocumentType =
      values.defaultRequirements?.filter(isDocumentTypeData) as DocumentTypeData[];
    companyData.defaultSettings.defaultPerk =
      values.defaultRequirements?.filter(isPerkData);
  }

  if (values.defaultRequirements && !values.defaultRequirements.length) {
    companyData.defaultSettings.defaultPerk = [];
    companyData.defaultSettings.defaultDocumentType = [];
  }

  if (
    values.job_classification_isco !== undefined || values.job_classification_nace !== undefined
  ) {
    companyData.defaultSettings.job_classification_ids = {
      cz_isco: values.job_classification_isco?.id,
      cz_nace: values.job_classification_nace?.id,
    };
  } else {
    companyData.defaultSettings.job_classification_ids = undefined;
  }

  return companyData;
};

const documentTypeService = feathersClient.service('document-type');

interface Contract {
  name: string;
  type: DocumentType;
  templateType: DocumentType;
}

const DEFAULT_DPC: Contract = {
  name: 'DPČ',
  type: DocumentType.CONTRACT_DPC,
  templateType: DocumentType.CONTRACT_DPC_TEMPLATE,
};

const DEFAULT_DPP: Contract = {
  name: 'DPP',
  type: DocumentType.CONTRACT_DPP,
  templateType: DocumentType.CONTRACT_DPP_TEMPLATE,
};

export const createDefaultContract = async (
  companyId: number,
  fulfillmentType: CompanyFulfillmentType,
) => {
  const documentDefaults =
    fulfillmentType === CompanyFulfillmentType.AGENCY
      ? DEFAULT_DPC
      : DEFAULT_DPP;

  const query = {
    type: documentDefaults.templateType,
    published_at: {
      $lt: moment().toISOString(),
    },
  };

  const documentTypeResp = await documentTypeService
    .find({ query })
    .catch(() =>
      ErrorAlert(
        `Nastala chyba. Nepodařilo se nalézt vzorovou šablonu pro ${documentDefaults.name}.`,
      ));
  if (!documentTypeResp?.data?.[0]?.template_id) {
    return ErrorAlert('Nastala chyba. Chybí id šablony.');
  }
  const [documentType] = documentTypeResp.data;

  return documentTypeService
    .create({
      name: documentDefaults.name,
      company_id: companyId,
      is_signable: documentType.is_signable,
      type: documentDefaults.type,
      template_id: documentType.template_id,
      published_at: moment().toISOString(),
      validity_start_at: documentType.validity_start_at,
      validity_end_at: documentType.validity_end_at,
      validity_duration: documentType.validity_duration,
      validity_duration_unit: documentType.validity_duration_unit,
    })
    .catch(async () => {
      ErrorAlert(
        `Nastala chyba. Nepodařilo se vytvořit záznam o firemní šabloně pro ${documentDefaults.name}.`,
      );
    });
};
